import { createRouter, createWebHistory } from 'vue-router'
import MyHome from '../views/MyHome.vue'

const routes = [
  {
    path: '/',
    name: 'MyHome',
    component: MyHome,
    meta: { title: '微信验证' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  document.title = to.meta.title || '';
  history.pushState(null, null, '/#');
  window.addEventListener('popstate', function () {
    history.pushState(null, null, '/#');
  });
});

export default router
